import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import store from './store'
import Vuelidate from 'vuelidate'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "./assets/css/custom.scss"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import UUID from "vue-uuid";
import VueTouch from 'vue-touch';
import * as Sentry from '@sentry/vue';

Vue.config.productionTip = process.env.NODE_ENV === 'development';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(VueTouch)
Vue.use(Toast, {
    newestOnTop: true,
    timeout: 5000,
    closeOnClick: true,
    closeButton: false,
    position: "top-center",
})
Vue.use(UUID)
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [Sentry.browserTracingIntegration()],
  release: "mainpage@" + process.env.VUE_APP_VERSION,
  environment: "staging",
  // Performance Monitoring
  sampleRate: 0.1, // Use for production
  tracesSampleRate: 0.1, // Lower the value in production
  initialScope: {
    tags: { "frontend-id": "mainpage" },
  },
  denyUrls: [/https?:\/\/st-a\.cdp\.asia/],
  enabled: !window.location.href.includes('localhost'),
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
