import Vue from "vue";
import Router from "vue-router";
import modules from "./modules";
import { flatten } from "lodash-es";
import store from "@/store";
import { SET_INFO } from "@/store/info/actions";
import { ACTION_RESET_REGO_SEARCHED } from "@/store/rego-search/actions";
import { VUE_APP_BASE } from "@/config";
import { LP_TYPE } from "@/config";
import topPageService from "@/services/top-page";
import { ACTION_REGO_SEARCH, ACTION_REGO_SEARCHED, } from "@/store/rego-search/actions";
import { SET_REGO_SEARCH_FLAG, SET_RB_LOOKUP_FINISH } from "@/store/info/actions";
import { SET_STATE } from "../store/info/actions";

import {
  CacheStatus,
  CACHE_MODULE_NAME,
  CACHE_STATUS,
  CLEAR_STORE_CACHE,
} from "../store/plugins/session-storage-persistence";
// } from "../store/plugins/local-storage-persistence";
import { eraseCookie, getCookie, setCookie } from "../helper/cookie";
import { isEmpty, decrypt, b64dec, logError } from "@/helper/utils";

Vue.use(Router);
const router = new Router({
  base: VUE_APP_BASE,
  mode: "history",
  routes: [
    {
      path: "/",
      name: "top-page",
      component: () => import("../views/top-page/Index"),
    },
    {
      path: "/",
      name: "layout",
      component: () => import("../components/layout/Index"),
      children: [...flatten(Object.values(modules))],
    },
    {
      path: "/*",
      name: "not-found",
      component: () => import("../views/error/404"),
    },
  ],
  scrollBehavior: scrollTop,
});

const route = {
  TOP_PAGE: "top-page",
  THANK_PAGE: "thanks-page",
};

function scrollTop(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
}

function resetStore(pathName) {
  if (pathName === route.TOP_PAGE) {
    store.dispatch("resetStep");
    store.dispatch("clearImages");
    store.dispatch(SET_INFO, {});
    store.dispatch(ACTION_RESET_REGO_SEARCHED);
    store.dispatch(SET_REGO_SEARCH_FLAG, false);
    store.dispatch(SET_RB_LOOKUP_FINISH, false);
  }
  store.commit(CLEAR_STORE_CACHE);
}

function setNextStep(nextStep) {
  store.dispatch("setStep", nextStep);
}

function setCacheStatus(status) {
  store.state[CACHE_MODULE_NAME][CACHE_STATUS] = status;
}

function decryptBpid() {
  // console.log(getCookie("qs"));
  const queryString = getCookie("qs");
  if (queryString) {
    try {
      store.dispatch("setQueryString", queryString);
      const position = queryString.search('enc=');
      let buf = queryString.slice(position + 4);
      buf = decodeURIComponent(buf).replace(/ /g, "+");
      const parts = buf.split(':');
      const encryptedString = Buffer.from(parts[1], 'base64');
      const decrypted = decrypt(b64dec(encryptedString));
      console.log('decrypted',decrypted);
      const urlParams = new URLSearchParams(decrypted);
  
      return urlParams;
    } catch (err) {
      logError(`[Router] decryptBpid error: ${err}`);
    }
  }
}
  
let lookupValue = "";
let regoValue = "";
let stateValue = "";

async function regoSearch(to, from, next) {
  if (store.getters.searched === false ) {
    store.dispatch(ACTION_REGO_SEARCHED);
    try {
      const searchResult = await topPageService.searchRego(
        {
          lookup: lookupValue,
          regoNumber: regoValue,
          state: stateValue,
          leadSource: LP_TYPE,
        }
      );
      if (searchResult.status === 201) {
        searchResult.data.location = stateValue;
        searchResult.data.rego = regoValue.toUpperCase();
        store.dispatch(SET_REGO_SEARCH_FLAG, true);
        store
          .dispatch(ACTION_REGO_SEARCH, searchResult.data)
          .then(() => {
            next({ name: "vehicle-info", query: to.query });
          });
      }
    } catch (err) {
      console.log('err res',err);
      if (err.response.status === 404) {
        Vue.$toast.warning(
          "We were unable to find your car information via our Rego search. Please fill in the information below."
        );
        store
          .dispatch(ACTION_REGO_SEARCH, {
            location: stateValue,
            rego: regoValue.toUpperCase(),
          })
          .then(() => {
            next({ name: "vehicle-info", query: to.query });
          });
      } else {
        logError(`[Router] Rego search error: ${err}`);
      }
    }
  }
}

router.beforeEach((to, from, next) => {
  if (!isEmpty(to.query)) {
    if (getCookie("qs")) eraseCookie("qs");
    setCookie("qs", new URLSearchParams(to.query).toString(), 1);
  } else {
    eraseCookie("qs");
  }
  const excludeRouteNames = [
    "privacy-policy",
    "terms-and-conditions",
    "photo-guide",
    "seller-fees",
    "seller-agreement",
  ];

  const excludeToPath = [
    "top-page",
    "privacy-policy",
    "terms-and-conditions",
    "photo-guide",
    "seller-fees",
    "seller-agreement",
  ];
  
  if (excludeRouteNames.includes(to.name)) {
    next();
    return;
  }
  let urlParams = "";

  if (lookupValue === "" || lookupValue === null) {
    urlParams = decryptBpid();

    if (urlParams) {
      !urlParams.get("lookup") ? lookupValue = '0' : lookupValue = urlParams.get("lookup");
      regoValue = urlParams.get("rego");
      stateValue = urlParams.get("state");
    }
  }

  switch (store.state[CACHE_MODULE_NAME][CACHE_STATUS]) {
    case CacheStatus.New:
      // console.log(CacheStatus.New, Date.now());
      if (lookupValue !== '0') {
        resetStore(to.name);
      }
      setCacheStatus(CacheStatus.Rehydrated);
      if (!store?.getters.step || store.getters.step === 0) {
        if (urlParams !== undefined && regoValue === '' && stateValue === '') {
          next({ name: "vehicle-info", query: to.query });
        } else if (lookupValue === "1") {
          regoSearch(to, from, next);
        } else {
          next({ name: "top-page", query: to.query });
        }
      } else if (lookupValue === "0") {
        next({ name: "vehicle-info", query: to.query });
        store.dispatch(SET_STATE, {
          regoValue: regoValue,
          stateValue: stateValue,
        });
      } else {
        next({
          name: store.getters.currentStep.name,
          query: from.query,
        });
      }
      break;

    case CacheStatus.Empty:
      // console.log(CacheStatus.Empty, Date.now());
      setCacheStatus(CacheStatus.New);
      if (urlParams) {
        if (lookupValue === "0") {
          setNextStep(1);
            store.dispatch("setProgress", 0);
            next({
              name: "vehicle-info",
              query: to.query,
            });
        } else {
          next({
            name: "vehicle-info",
            query: to.query,
          });
        }
      } else {
        next({ name: "top-page", query: to.query });
      }
      break;

    default:
      if (to.name === route.TOP_PAGE || to.name === route.THANK_PAGE) {
        resetStore(to.name);
        next();
      } else if (
        from.name === "thanks-page" &&
        !excludeToPath.includes(to.name)
      ) {
        next({ name: "top-page" });
      } else {
        // check step
        if (to.meta.nextable) {
          next();
        } else {
          setNextStep(to.meta.step);
          if (from.name === "top-page" && to.name === "vehicle-info") {
            store.dispatch("setProgress", 0);
            store.dispatch("resetProgressStep");
          }
          next();
        }
      }
      break;
  }
});

export default router;
